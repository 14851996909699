import React from 'react';
import classnames from 'classnames';
import './HighlightedText.scss';

type HighlightedTextProps = {
  enforcedCase?: boolean;
  children: React.ReactText;
  highlight?: string;
  hightlightClassName?: string;
  className?: string;
};

function HighlightedText({
  children,
  highlight,
  className,
  hightlightClassName,
}: HighlightedTextProps) {
  if (highlight) {
    // Split text on higlight term, include term itself into parts, ignore case
    let parts = `${children}`.split(new RegExp(`(${highlight})`, 'gi')).filter((p) => p !== '');
    const highlightedClassName = classnames('highlighted--default', hightlightClassName);
    return (
      <span className={className}>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <mark key={index.toString()} className={highlightedClassName}>
              {part}
            </mark>
          ) : (
            part
          )
        )}
      </span>
    );
  }

  return <span className={className}>{children}</span>;
}

export default HighlightedText;
