const config = {
  title: 'Connectors',
  subtitle:
    'Payment Manager connectors are created by the community to expand the capabilities of PM4ML.',
  connectors: [
    {
      name: 'Connector Name',
      author: 'ModusBox Inc',
      description: `Supporting financial service operations 24/7 has tradiitonally been performed by a team of specialists. 
      Payment Manager gives the controls to efficiently identify root causes quickly and even enable predictive support and monitoring. 
      <br/> 
      <br/> 
      Identifying the source of issues enables you to go straight to the people and that need to react. 
      Payment Manager enables technical operations and makes it possible to manage them, with minimal staff.`,
    },
    {
      name: 'ISO-8083',
      author: 'Coil',
      description: `Supporting financial service operations 24/7 has tradiitonally been performed by a team of specialists. 
        Payment Manager gives the controls to efficiently identify root causes quickly and even enable predictive support and monitoring. 
        <br/> 
        <br/> 
        Identifying the source of issues enables you to go straight to the people and that need to react. 
        Payment Manager enables technical operations and makes it possible to manage them, with minimal staff.`,
    },
    {
      name: 'Connector Name',
      author: 'ModusBox Inc',
      description: `Supporting financial service operations 24/7 has tradiitonally been performed by a team of specialists. 
        Payment Manager gives the controls to efficiently identify root causes quickly and even enable predictive support and monitoring. 
        <br/> 
        <br/> 
        Identifying the source of issues enables you to go straight to the people and that need to react. 
        Payment Manager enables technical operations and makes it possible to manage them, with minimal staff.`,
    },
  ],
};

export default config;
