import React, { useState } from 'react';
import sanitize from 'sanitize-html';

import './connectors.scss';
import SearchBar from '../../components/SearchBar';
import Layout from '../../components/Layout';
import HighlightedText from '../../components/HighlightedText';
import { REPO_ADDRESS } from '../../data/links';
import config from '../../data/connectorsPage';
import LaunchIcon from '../../components/LaunchIcon';

type ConnectorItem = {
  name: string;
  author: string;
  description: string;
  logo?: string;
};

type ConnectorResultProps = ConnectorItem & {
  highlight?: string;
};

function ConnectorResult({ name, author, description, logo, highlight }: ConnectorResultProps) {
  const options = {
    allowedTags: ['br', 'span'],
    allowedAttributes: {
      br: ['class'],
    },
  }; //Check documentation for reference
  return (
    <div className="columns connector-result__card">
      <div className="column is-one-fifth center">
        <figure className="image">
          <LaunchIcon/>
        </figure>
      </div>
      <div className="column">
        <HighlightedText
          highlight={highlight}
          className="is-size-4-desktop is-size-5-mobile"
          hightlightClassName="highlighted-text"
        >
          {name}
        </HighlightedText>
        <div className="label-contributed">
          <span className="is-size-6-desktop is-size-6-mobile is-spaced-right">
            Contributed by:
          </span>
          <HighlightedText
            highlight={highlight}
            className="is-size-6-desktop is-size-6-mobile is-bold"
            hightlightClassName="highlighted-text"
          >
            {author}
          </HighlightedText>
        </div>
        <p
          className="is-size-6-desktop is-size-6-mobile content-text"
          dangerouslySetInnerHTML={{ __html: sanitize(description, options) }}
        ></p>
      </div>
    </div>
  );
}

function Page() {
  const [connectors, setConnectors] = useState(config.connectors || []);
  const [query, setQuery] = useState<string | undefined>(undefined);

  function onSearch(criteria?: string) {
    setQuery(criteria === '' ? undefined : criteria);
    let filtered = config.connectors;
    if (criteria !== undefined && criteria !== '') {
      filtered = connectors.filter(
        (connector: ConnectorResultProps) =>
          connector.name.includes(criteria) ||
          connector.author.includes(criteria) ||
          connector.description.includes(criteria)
      );
    }

    setConnectors(filtered);
  }
  return (
    <Layout className="connectors-page">
      <section className="hero is-light">
        <div className="hero-body showcase-container">
          <div className="container has-text-centered">
            <div className="title-section">
              <span className="title is-size-2-desktop is-size-3-mobile has-text-weight-semibold is-spaced">
                {config.title}
              </span>
              <span className="subtitle is-size-4-desktop is-size-5-mobile mw-500 main-subtitle">
                {config.subtitle}
              </span>
            </div>
          </div>
        </div>
      </section>
      <div className="content-wrapper container is-desktop">
        <section>
          <div className="container center is-centered">
            <div className="columns is-centered m-b-60 reverse-order">
              <div className="column">
                <SearchBar onSearch={onSearch} debounceDelay={500} />
                {query !== undefined && (
                  <span className="search-indicator">{`Showing ${connectors.length} result(s).`}</span>
                )}
              </div>
            </div>
            <div className="columns is-centered m-b-60 reverse-order">
              <div className="column">
                {connectors.map((props, index) => (
                  <ConnectorResult {...props} key={index.toString()} highlight={query} />
                ))}
              </div>
            </div>
          </div>
        </section>
        <div className="container has-text-centered">
          <p className="is-size-3-desktop is-size-5-mobile">Start Using Payment Manager Now!</p>
          <div className="button-group">
            <a className="button is-primary" href={REPO_ADDRESS}>
              Git
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Page;
