import React, { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import './index.scss';

type SearchBarProps = {
  debounceDelay?: number;
  onSearch: (criteria: string) => void;
};

export default function SearchBar({ onSearch, debounceDelay = 300 }: SearchBarProps) {
  const [userQuery, setUserQuery] = useState('');
  const delayedQuery = useCallback(
    debounce((q) => onSearch(q), debounceDelay),
    [debounceDelay]
  );
  const onChange = (e: any) => {
    setUserQuery(e.target.value);
    delayedQuery(e.target.value);
  };

  return (
    <div className="control has-icons-left search-bar__component">
      <input
        className="input is-primary is-rounded"
        type="text"
        placeholder="Search Connectors..."
        onChange={onChange}
        value={userQuery}
      />
      <span className="icon is-medium is-left">
        <i className="fas fa-search"></i>
      </span>
    </div>
  );
}
